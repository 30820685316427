import { useEffect, useState, CSSProperties } from 'react';
import { useLoaderData } from 'react-router-dom';

import '../../styles/css/revamp_style.css'
import TopCareerHorizontal from './TopCareerHorizontal';
import TopCareerVertical from './TopCareerVertical';
import TopCareerPieChart from './TopCareerPieChart';
import TopCompaniesVertical from './TopCompaniesVertical';
import TopCompaniesHorizontal from './TopCompaniesHorizontal';
import TopperZoneVertical from './TopperZoneVertical';
import TopperZoneHorizontal from './TopperZoneHorizontal';
import DirectoryStats from './DirectoryStats';
import GuidingAlumniList from './GuidingAlumniList';
import AlumniSpeakList from './AlumniSpeakList';
import NotableAlumniList from './NotableAlumniList';
import EclecticCareerList from './EclecticCareer';
import { initTooltip } from '../../library/Core/SmartContext';
import AppealVideo from './AppealVideo';
import AlumniSpotlight from './AlumniSpotlight';
import CoverBanner from './CoverBanner';
import EnquiryForm from './interactive_widgets/EnquiryForm';
import AdmissionReferralForm from './interactive_widgets/AdmissionReferralForm';
import { INTERACTIVE_POPUP_WIDGETS } from '../../constants/constants';
import WidgetModal from '../WidgetModal';

const RenderWidget = () => {
    const [params, setParams]: any = useState({});
    const loaderData = useLoaderData() as any;
    const defaultWidget = <div>Widget not found/Published</div>;
    const [widgetClass, setWidgetClass] = useState('');
    const [primaryClass, setPrimaryClass] = useState<CSSProperties>({});
    const [fullWidth, setFullWidth] = useState(false);

    let wgdata = loaderData?.data?.wgdata;
    let wginfodata = loaderData?.data?.wginfodata;
    console.log(loaderData?.data, 'loaderData?.data 34');
    const widgetCode = wgdata?.code;
    const layoutType = wgdata?.layout_type;
    const chartType = wginfodata?.data?.widgetData?.chartType;
    const moreCardsClass = 'more-cards';
    let gridLayoutLength = 4;
    let moreCardsFlag = false;

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const callFrom = urlParams.get('callFrom');
        setParams({ callFrom });
        initTooltip();
    }, []);

    console.log(widgetCode, 'widgetCode 49');
    const renderWidget = () => {
        if (params?.callFrom != 'preview' && wgdata?.is_published == 0) {
            return defaultWidget;
        }

        let widget;
        let customClass: any = 'bg-gray-EFEFEF';
        let primaryColor: any = {};
        let fullWidthFlag = false;
        switch (widgetCode) {
            case 'top_careers':
            case 'top_study_country':
            case 'current_location_country':
            case 'alumni_occupation':
                if (chartType === 'vertical_bars') {
                    widget = <TopCareerVertical widgetData={loaderData} />;
                } else if (chartType === 'horizontal_bars') {
                    widget = <TopCareerHorizontal widgetData={loaderData} />;
                } else {
                    widget = <TopCareerPieChart widgetData={loaderData} />;
                }
                break;

            case 'top_companies':
            case 'top_colleges':
                if (wginfodata?.data?.widgetData?.menuTabs == 'vertical') {
                    widget = <TopCompaniesVertical widgetData={loaderData} />;
                } else {
                    widget = <TopCompaniesHorizontal widgetData={loaderData} />;
                }
                break;

            case 'directory_stats':
                widget = <DirectoryStats widgetData={loaderData} />;
                break;

            case 'guiding_alumni_mentors':
                widget = <GuidingAlumniList widgetData={loaderData} />;
                // customClass = 'bg-blue-164F88 owl-carousel-cards';
                customClass = 'bg-blue-164F88';
                if(!wgdata?.primary_color || wgdata?.primary_color === null){
                    primaryColor = {};

                } else {
                    primaryColor = {
                        backgroundColor: wgdata?.primary_color || '', // Fallback color if primary_color is undefined
                    };

                }
                
                
                
                break;

            case 'notable_alumni_mentors':
                widget = <NotableAlumniList widgetData={loaderData} />;
                if(!wgdata?.primary_color || wgdata?.primary_color === null){
                    primaryColor = {};

                } else {
                    primaryColor = {
                        backgroundColor: wgdata?.primary_color || '', // Fallback color if primary_color is undefined
                    };

                }
                break;

            case 'alumni-speak':
                widget = <AlumniSpeakList widgetData={loaderData} />;
                gridLayoutLength = loaderData?.data?.wgdata?.grid_layout;
                if(!wgdata?.primary_color || wgdata?.primary_color === null){
                    primaryColor = {};

                } else {
                    primaryColor = {
                        backgroundColor: wgdata?.primary_color || '', // Fallback color if primary_color is undefined
                    };

                }
                break;
            case 'eclectic_career_alumni':
            case 'entrepreneur_alumni':
                widget = <EclecticCareerList widgetData={loaderData} />;
                customClass = 'bg-blue-164F88';
                if(!wgdata?.primary_color || wgdata?.primary_color === null){
                    primaryColor = {};

                } else {
                    primaryColor = {
                        backgroundColor: wgdata?.primary_color || '', // Fallback color if primary_color is undefined
                    };

                }
            break;
                
            case 'top_industries':
                if (chartType === 'vertical_bars') {
                    widget = <TopCareerVertical widgetData={loaderData} colorClass="lite-red" slant={true} />;
                } else if (chartType === 'horizontal_bars') {
                    widget = <TopCareerHorizontal widgetData={loaderData} />;
                } else {
                    widget = <TopCareerPieChart widgetData={loaderData} />;
                }
                break;
            
            case 'appeal_video':
                widget = <AppealVideo widgetData={loaderData} />;
                break;

            case 'spotlight':
                customClass = 'bg-pink-FFEFF1';
                widget = <AlumniSpotlight widgetData={loaderData} />;
                break;
        
            case 'cover_banner':
                fullWidthFlag = true;
                widget = <CoverBanner widgetData={loaderData} />;
                break;

            case 'topper_zone':
                if (layoutType == '1') {
                    widget = <TopperZoneHorizontal widgetData={loaderData} />;
                } else {
                    widget = <TopperZoneVertical widgetData={loaderData} />;
                }
                customClass = 'bg-blue-164F88';
                // if(!wgdata?.primary_color || wgdata?.primary_color === null){
                //     primaryColor = {};

                // } else {
                //     primaryColor = {
                //         backgroundColor: wgdata?.primary_color || '', // Fallback color if primary_color is undefined
                //     };

                // }
                break;
            
            case 'enquiry_form':
                widget = <EnquiryForm widgetData={loaderData} params={params}/>;
                break;

            case 'admission_referral_form':
                widget = <AdmissionReferralForm widgetData={loaderData} params={params} />;
                break;

            // Add more cases here for other widget codes and types
            default:
                widget = defaultWidget;
        }

        moreCardsFlag = loaderData?.data?.instWiseListData?.length > gridLayoutLength;

        if (customClass !== widgetClass) {
            setWidgetClass(customClass);
        }

        if (JSON.stringify(primaryColor) !== JSON.stringify(primaryClass)) {
            setPrimaryClass(primaryColor);
        }
        
        


        
        if ( INTERACTIVE_POPUP_WIDGETS.includes(wgdata?.code) && params?.callFrom != 'preview' ) {
            fullWidthFlag = true;
        }

        if (fullWidthFlag !== fullWidth) {
            setFullWidth(fullWidthFlag);
        }

        return widget;
    };
    
    return (
        <>
            <div className={` ${widgetClass} position-relative`} style={primaryClass}>
                <div className={`${!fullWidth ? 'max-1140 py-5' : ''} mx-auto`}>
                    {renderWidget()}
                    {wgdata?.membership != 'paid' && (
                        <div className={`d-flex justify-content-end mt-3 uni-logo-small position-absolute bottom-20 right-20 z-1 ${moreCardsFlag ? moreCardsClass : ''}`}><img data-bs-toggle="tooltip" title="Powered by Univariety" src={`${process.env.REACT_APP_IMAGE_BASEPATH}/university-logo-shape.png`} alt="" /></div>
                    )}

                    {wgdata?.from_status === 1 && wgdata?.from_id ? (
                        <WidgetModal className='btn-green mx-auto mt-4' formId={wgdata.from_id} />
                    ) : null}

                </div>
            </div>
        </>
    );
};

export default RenderWidget;
