import React, { useEffect, useState } from "react";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { isEmpty } from '../../library/Core/SmartContext';
interface MetricData {
    value: number | string;
    rowUUID: string;
    metricType: string;
    metricEntity2: string;
}

interface WidgetData {
    data: {
        instWiseListData: any;
        wgdata: any;
        wginfodata: {
            data: {
                metricData: MetricData[];
                widgetData: any;
                popupData: any;
            }
        }
    };
    internal: any;
}

interface TopperZoneHorizontalProps {
    widgetData: WidgetData;
}

const decadeRanges = [
    { key: 1, label: "1971-1980" },
    { key: 2, label: "1981-1990" },
    { key: 3, label: "1991-2000" },
    { key: 4, label: "2001-2010" },
    { key: 5, label: "2011-2020" },
    { key: 6, label: "2021-2030" }
  ];

  // Explicitly typing decadeLabelMap to allow number indexing
  const decadeLabelMap: Record<number, string> = decadeRanges.reduce(
    (acc: Record<number, string>, { key, label }: { key: number; label: string }) => {
      acc[key] = label;
      return acc;
    },
    {}
  );

const TopperZoneHorizontal: React.FC<TopperZoneHorizontalProps> = ({ widgetData }) => {
  const data = widgetData?.data?.wgdata;
    //const alumniData: any = widgetData?.data?.instWiseListData;

  const alumniData: any[] = widgetData?.data?.instWiseListData;
  let secondaryColor: any = {};
  let primaryColor: any = {};

primaryColor = {
    backgroundColor: data?.primary_color || '#4096D9', // Use primary_color instead
    border: `1px solid ${data?.primary_color || '#4096D9'}`, // Properly formatted primary border color
};

secondaryColor = {
    backgroundColor: data?.secondary_color || '', // Background color fallback
    border: `1px solid ${data?.secondary_color || ''}`, // Properly formatted border color
};

  const [groupedData, setGroupedData] = useState<{ [key: string]: any }>({});
  const [activeTab, setActiveTab] = useState<string>("");

  useEffect(() => {
    // Group alumni data by numeric `batch_decade`
    const grouped = alumniData.reduce((acc, item) => {
      const { batch_decade } = item;
      const decadeKey = parseInt(batch_decade); // Ensure batch_decade is numeric
      
      // Initialize group if it doesn't exist
      if (!acc[decadeKey]) {
        const label = decadeRanges.find(decade => decade.key === decadeKey)?.label || "Unknown";
        acc[decadeKey] = { label, count: 0, list: [] };
      }
      acc[decadeKey].count += 1;
      acc[decadeKey].list.push(item);
      return acc;
    }, {});

    setGroupedData(grouped);
    // Set default active tab to the first key in groupedData
    const firstDecadeKey = Object.keys(grouped)[0];
    if (firstDecadeKey) {
      setActiveTab(firstDecadeKey);
    }
  }, [alumniData]);

  

    console.log(groupedData, 'groupedData')
    // OwlCarousel options
    const options = {
        margin: 10,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
        },
    };

    return (
        <>
            <h4 className="font-30 font-600 text-center mb-2 text-white">
                {data?.large_heading}
            </h4>
            <p className="text-center pb-3 text-white">
                {data?.medium_heading}
            </p>

            <div className='max-1140 mx-auto TopperSlider'>
                <div className='scroll'>
                    <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                            {/* <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-india-tab" data-bs-toggle="pill" data-bs-target="#pills-india" type="button" role="tab" aria-controls="pills-india" aria-selected="false">1971 - 1980</button>
                            </li> */}
                            {Object.keys(groupedData).map((decade) => (
                                <li className="nav-item" role="presentation" key={decade}>
                                <button
                                    className="nav-link" // Keep class names static here
                                    style={activeTab === decade ? primaryColor : secondaryColor} // Apply styles dynamically
                                    onClick={() => setActiveTab(decade)}
                                    data-bs-toggle="pill"
                                    data-bs-target={`#pills-${decade}`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`pills-${decade}`}
                                    aria-selected={activeTab === decade}
                                >
                                    {decadeLabelMap[parseInt(decade)] || "Unknown"}
                                </button>
                                </li>
                            ))}
                            
                    
                        </ul>
                    </div>

            </div>

            <div className="tab-content pt-2" id="pills-tabContent">
            {Object.entries(groupedData).map(([decade, { list }]) => (
          <div
            key={decade}
            className={`tab-pane fade ${activeTab === decade ? "active show" : ""}`}
            id={`pills-${decade}`}
            role="tabpanel"
            aria-labelledby={`pills-${decade}-tab`}
          >
            <OwlCarousel className="owl-theme fourcardslider" {...options}>
              {list.map((alum: any, index: number) => (
                <div className="item" key={index}>
                  <div className="guiding_alumni_block h-100">
                    <div className="guiding_alumni_top">
                      <div className="guiding_alumni_profile_img">
                        <img
                          src={alum?.profile_pic}
                          width="80"
                          alt={alum?.user_name}
                          onError={(e) => {
                            e.currentTarget.src = `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student-boy.svg`;
                          }}
                        />
                      </div>
                      <div className="guiding_alumni_profile_name text-yellow">{alum?.user_name}</div>
                      <div className="font-16 font-500">{alum?.user_program?.trim() && `${alum?.user_program.trim()}`} {alum?.user_year?.trim() && `, ${alum?.user_year.trim()}`}</div>
                    </div>
                    <div className="guiding_alumni_college_cource">
                      <ul>
                        <li>
                          <i className="badge"></i>
                          <span>
                            {`${alum?.marks_grade.trim()}`}
                          </span>
                        </li>
                        {alum?.select_ug_college === 1 && (
                          <li>
                            <i className="tool"></i> <span>{alum?.joined_ug_course || "N/A"}</span>
                          </li>
                        )}
                        {alum?.select_ug_college === 1 && (
                          <li>
                            <i className="cap icon-yellow"></i> <span>{alum?.joined_ug_college || "N/A"}</span>
                          </li>
                        )}
                        {alum?.select_pg_college === 1 && (
                          <li>
                            <i className="tool"></i> <span>{alum?.joined_pg_course || "N/A"}</span>
                          </li>
                        )}
                        {alum?.select_pg_college === 1 && (
                          <li>
                            <i className="cap icon-yellow"></i> <span>{alum?.joined_pg_college || "N/A"}</span>
                          </li>
                        )}
                        {alum?.select_pg_company === 1 && (
                          <li>
                            <i className="brifecase"></i>
                            <span>
                              {alum?.current_designation}
                              {alum?.current_designation && alum?.current_company && ` at `}
                              {alum?.current_company}
                            </span>
                          </li>
                        )}
                        {alum?.select_current_location === 1 && (
                          <li>
                            <i className="icon-location-pin text-yellow me-2 mt-1 ms-1"></i> <span>{alum?.current_location || "N/A"}</span>
                          </li>
                        )}
                        {alum?.select_achievement === 1 && (
                          <li>
                            <i className="star"></i> <span>{alum?.data_achievement || "N/A"}</span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        ))}
             
            </div>
            
        </>
    );
};

export default TopperZoneHorizontal;
