import React, { CSSProperties, useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { isEmpty } from '../../library/Core/SmartContext';

interface MetricData {
    value: number | string;
    rowUUID: string;
    metricType: string;
    metricEntity: string;
}

interface WidgetData {
    data: {
        wgdata: any;
        wginfodata: {
            data: {
                metricData: MetricData[];
                widgetData: {
                    other: number;
                    heading: string;
                    chartType: string;
                };
            }
        }
    };
    internal: any;
}

interface TopCareerProps {
    widgetData: WidgetData;
}

const TopCareerHorizontal: React.FC<TopCareerProps> = ({ widgetData }) => {
    const [secondaryClass, setSecondaryClass] = useState<CSSProperties>({});
    const [primaryClass, setPrimaryClass] = useState<CSSProperties>({});
    let metricData = widgetData?.data?.wginfodata?.data?.metricData;
    let data = widgetData?.data?.wginfodata?.data?.widgetData;
    let widgetdata = widgetData?.data?.wgdata;
    let metricEntityDomain = widgetData?.internal?.[widgetData?.data?.wgdata?.code];
    let secondaryColor: any = '';

    secondaryColor= {
        backgroundColor: widgetdata?.secondary_color || '', // Fallback color if secondary_color is undefined
    };

    if (JSON.stringify(secondaryColor) !== JSON.stringify(secondaryClass)) {
        setSecondaryClass(secondaryColor);
    }

    let primaryColor: any = {};
  
    primaryColor = {
        backgroundColor: widgetdata?.primary_color || '', // Fallback color if secondary_color is undefined
    };

    if (JSON.stringify(primaryColor) !== JSON.stringify(primaryClass)) {
        setPrimaryClass(primaryColor);
    }

    
    console.log(secondaryClass, 'widgetData value')
    console.log(primaryClass, 'widgetData value')
    return (
        <div className="Vertical-graph-block mx-auto">
            <h2>{data?.heading}</h2>
            <div className="bigHorizontalProgress">
                {metricData?.filter((data: any) => (data.value > 0 && !isEmpty(data?.metricEntity))).map((data: any, index: any) => (
                    <div key={index} className="HorizontalProgress">
                        <div className="graphContainer">
                            <div className="progress lite-orange" data-bs-toggle="tooltip" title={`${data.value}%`} style={primaryClass}>
                                <div className="progress-bar dark-brown" role="progressbar" style={{ width: `${data.value}%`, ...secondaryClass }} aria-valuenow={data.value} aria-valuemin={0} aria-valuemax={100}></div>
                                <span className="progresstext">{data.value}%</span>
                            </div>
                        </div>
                        <label className="caption">
                            <span className="font-14 font-500">{metricEntityDomain[data.metricEntity]?.value}</span>
                        </label>
                    </div>
                ))}

                {data.other > 0 && (
                    <div key="other" className="HorizontalProgress">
                        <div className="graphContainer">
                            <div className="progress lite-orange" data-bs-toggle="tooltip" title={`${data.other}%`} style={primaryClass}>
                                <div className="progress-bar dark-brown" role="progressbar" style={{ width: `${data.other}%`, ...secondaryClass }} aria-valuenow={data.other} aria-valuemin={0} aria-valuemax={100}></div>
                                <span className="progresstext">{data.other}%</span>
                            </div>
                        </div>
                        <label className="caption">
                            <span className="font-14 font-500">Other</span>
                        </label>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TopCareerHorizontal;