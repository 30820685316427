import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

interface Alumni {
    name: string;
    headline: string;
    profileImg: string;
    logoImg: string;
}

interface WidgetData {
    data: {
        instWiseListData: any;
        wgdata: any;
        wginfodata: {
            data: {
                widgetData: Alumni[];
            };
        };
    };
    internal: any;
}

interface AlumniSpotlightProps {
    widgetData: WidgetData;
}

const AlumniSpotlight: React.FC<AlumniSpotlightProps> = ({ widgetData }) => {
    console.log(widgetData, "widgetData")
    const alumniData: any = widgetData?.data?.instWiseListData;
    const wgdata: any = widgetData?.data?.wgdata;

    let secondaryColor: any = {};
    let primaryColor: any = {};
  
    primaryColor = {
        backgroundColor: wgdata?.primary_color || '#4096D9', // Use primary_color instead
        border: `1px solid ${wgdata?.primary_color || '#4096D9'}`, // Properly formatted primary border color
    };
    
    console.log(wgdata?.secondary_color, 'secondary alumniData?.secondary_color')

    secondaryColor = {
        color: wgdata?.secondary_color && wgdata?.secondary_color.trim() !== '' 
            ? wgdata.secondary_color 
            : '#000000', // Default color if secondary_color is empty or undefined
    };
    console.log(secondaryColor, 'secondary Color')
    // Define Owl Carousel options
    const options = {
        items: 1,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true
    };

    return (
        <>
        <h4 className="font-30 font-600 text-center mb-0">{widgetData?.data?.wgdata?.large_heading}</h4>
        <div className="alumni-card-wrap pt-4">
            <div className='alumni-card-slider'>
                <i className='curve' style={primaryColor}></i>
                <OwlCarousel className="owl-carousel owl-theme owl-loaded owl-drag" {...options}>
                
                {alumniData.map((alumni: any, index: any) => (
                    <>
                    <div key={index} className="item">
                    
                        <div className="alumni-card">
                            <div className="alumni-detail">
                                <div className="alumni-img-wrap">
                                    <div className="alumni-img">
                                        <img
                                            src={alumni?.profile_pic}
                                            width="80"
                                            className="w-auto"
                                            alt={`Profile of ${alumni?.user_name}`}
                                        />
                                    </div>
                                </div>
                                <h3 style={secondaryColor}>
                                    {alumni?.user_name}
                                </h3>
                                <h4>
                                    {alumni?.headline_spotlight}
                                </h4>
                            </div>
                            <div className="alumni-logo">
                                <a href="javascript:;" className="alumni-link">
                                    <img
                                        src={alumni?.inst_profile_pic}
                                        width="90"
                                        className="img-fluid"
                                        alt="Alumni Logo"
                                    />
                                </a>
                            </div>
                        </div>
                    </div></>
                    
                ))}
            </OwlCarousel>
            </div>
            
        </div>
        </>
    );
};

export default AlumniSpotlight;
