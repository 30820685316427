import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS

function WidgetModal({ className, formId }) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Button className={className}  onClick={handleShow}>
          <span>Enquiry Form</span>
        </Button>
  
        <Modal show={show} onHide={handleClose} centered >
          <Modal.Header closeButton>
            
          </Modal.Header>
          <Modal.Body>
            <div><iframe 
            src={`${process.env.REACT_APP_APP_BASE_URL}/widget/${formId}`}
            title="Enquiry Form" 
            width="100%" 
            height="465px"
            loading="lazy"
            description="Enquiry Form"
        ></iframe></div>
          </Modal.Body>
        </Modal>
      </>
    );
}

export default WidgetModal;

