import React from 'react';
import { useEffect, useState, CSSProperties } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { isEmpty } from '../../library/Core/SmartContext';

interface MetricData {
    value: number | string;
    rowUUID: string;
    metricType: string;
    metricEntity2: string;
}

interface WidgetData {
    data: {
        instWiseListData: any;
        wgdata: any;
        wginfodata: {
            data: {
                metricData: MetricData[];
                widgetData: any;
                popupData: any;
            }
        }
    };
    internal: any;
}

interface EclecticCareerListProps {
    widgetData: WidgetData;
}

const EclecticCareerList: React.FC<EclecticCareerListProps> = ({ widgetData }) => {
    const [secondaryClass, setSecondaryClass] = useState<CSSProperties>({});
    const data = widgetData?.data?.wgdata;
    const alumniData: any = widgetData?.data?.instWiseListData;
    let secondaryColor: any = '';

    secondaryColor= {
        color: data?.secondary_color || '#F5AE18', // Fallback color if secondary_color is undefined
    };

    if (JSON.stringify(secondaryColor) !== JSON.stringify(secondaryClass)) {
        setSecondaryClass(secondaryColor);
    }
    // OwlCarousel options
    const options = {
        margin: 10,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
        },
    };

    return (
        <>
            <h4 className="font-30 font-600 text-center text-white mb-2">
                {data?.large_heading}
            </h4>
            <p className="text-center text-white  pb-3">
                {data?.medium_heading}̥̥
            </p>
            <OwlCarousel className="owl-theme fourcardslider"  {...options}>
                {alumniData.map((alum: any, index: any) => (
                    <div className="item" key={index}>
                        <div className="guiding_alumni_block h-100">
                            <div className="guiding_alumni_top">
                                <div className="guiding_alumni_profile_img">
                                    <img
                                        src={alum?.profile_pic}
                                        width="80"
                                        alt={alum?.user_name}
                                        onError={(e) => {
                                            e.currentTarget.src = `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student-boy.svg`;
                                        }}
                                    />
                                </div>
                                <div className="guiding_alumni_profile_name" style={secondaryClass} >{alum?.user_name}</div>
                                <div className="text-center font-500">Class of {alum?.user_year}</div>
                            </div>
                            <div className="guiding_alumni_college_cource">
                                <ul>
                                    {alum?.select_pg_company == 1 && (
                                        <li>
                                            <i className="brifecase"></i>
                                            <span>
                                                {alum?.current_designation}
                                                {!isEmpty(alum?.current_designation) && !isEmpty(alum?.current_company) && ` at `}
                                                {isEmpty(alum?.current_designation) && isEmpty(alum?.current_company) && `N/A`}
                                                {alum?.current_company}
                                            </span>
                                        </li>
                                    )}

                                    {alum?.select_current_location == 1 && (
                                        <li>
                                            <i className="icon-location-pin text-yellow me-2 mt-1 ms-1"></i> <span>{!isEmpty(alum?.current_location) ? alum?.current_location : `N/A`}</span>
                                        </li>
                                    )}

                                    {alum?.select_achievement == 1 && (
                                        <li>
                                            <i className="star"></i> <span>{!isEmpty(alum?.data_achievement) ? alum?.data_achievement : `N/A`}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
        </>
    );
};

export default EclecticCareerList;