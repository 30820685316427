import React from 'react';
import { useEffect, useState, CSSProperties } from 'react';
interface MetricData {
    value: number | string;
    rowUUID: string;
    metricType: string;
    metricEntity2: string;
}

interface WidgetData {
    data: {
        wgdata: any;
        wginfodata: {
            data: {
                metricData: MetricData[];
                widgetData: any;
                popupData: any;
            }
        }
    };
    internal: any;
}

interface DirectoryStatsProps {
    widgetData: WidgetData;
}

const DirectoryStats: React.FC<DirectoryStatsProps> = ({ widgetData }) => {
    const [secondaryClass, setSecondaryClass] = useState<CSSProperties>({});
    const [primaryClass, setPrimaryClass] = useState<CSSProperties>({});
    let metricData = widgetData?.data?.wginfodata?.data?.metricData;
    let data = widgetData?.data?.wginfodata?.data?.widgetData;
    let widgetdata = widgetData?.data?.wgdata;
    let metricEntityDomain = widgetData?.internal?.[widgetData?.data?.wgdata?.code];
    console.log(widgetdata, 'widgetdata value')
    let secondaryColor: any = '';

    secondaryColor= {
        color: widgetdata?.secondary_color || '', // Fallback color if secondary_color is undefined
    };

    if (JSON.stringify(secondaryColor) !== JSON.stringify(secondaryClass)) {
        setSecondaryClass(secondaryColor);
    }

    let primaryColor: any = {};
  
    primaryColor = {
        color: widgetdata?.primary_color || '', // Fallback color if secondary_color is undefined
    };

    if (JSON.stringify(primaryColor) !== JSON.stringify(primaryClass)) {
        setPrimaryClass(primaryColor);
    }

    console.log(secondaryClass, 'widgetData value')
    console.log(primaryClass, 'widgetData value')
    const METRIC_ENTITY_CLASS_MAP: any = {
        joined_alumni: 'hat',
        different_career_chosen: 'brifecase',
        companies_represented: 'building',
        countries_represented: 'global',
        alumni_at_top_colleges: 'college',
        alumni_at_top_companies: 'building',
        scholarship_received: 'scholarship'
    }
    return (
        <>
            <h4 className="font-30 font-600 text-center mb-2">
                {data?.largeHeading}
            </h4>
            <p className="text-center pb-3">
                {data?.mediumHeading}
            </p>
            <div className="alumni_network_count">
                <ul>
                    {metricData?.filter((data: any) => data?.statMetricEntity != "").map((data: any, index: any) => (
                        <li key={data?.rowUUID}>
                            <div className={`icon ${METRIC_ENTITY_CLASS_MAP[data?.statMetricEntity]}`}></div>
                            <b  style={primaryClass}>{data.statMetricEntity == 'scholarship_received' && ('₹')} {data?.displayValue}</b>
                            <p className="max-170"  style={secondaryClass}>
                                {metricEntityDomain[data.statMetricEntity]?.value}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default DirectoryStats;
