import React from 'react';
import { useEffect, useState, CSSProperties } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';

interface MetricData {
    value: number | string;
    rowUUID: string;
    metricType: string;
    metricEntity2: string;
}

interface WidgetData {
    data: {
        instWiseListData: any;
        wgdata: any;
        wginfodata: {
            data: {
                metricData: MetricData[];
                widgetData: any;
                popupData: any;
            }
        }
    };
    internal: any;
}

interface AlumniSpeakListProps {
    widgetData: WidgetData;
}

const AlumniSpeakList: React.FC<AlumniSpeakListProps> = ({ widgetData }) => {
    const [secondaryClass, setSecondaryClass] = useState<CSSProperties>({});
    const data = widgetData?.data?.wgdata;
    const alumniData: any = widgetData?.data?.instWiseListData;

    let secondaryColor: any = '';

    secondaryColor= {
        color: data?.secondary_color || '', // Fallback color if secondary_color is undefined
    };

    if (JSON.stringify(secondaryColor) !== JSON.stringify(secondaryClass)) {
        setSecondaryClass(secondaryColor);
    }
    // OwlCarousel options
    const options = {
        margin: 10,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: data?.grid_layout ?? 2
            },
        },
    };

    return (
        <>
            <h4 className="font-30 font-600 text-center mb-2">
                {data?.large_heading}
            </h4>
            <p className="text-center pb-3">
                {data?.medium_heading}
            </p>
            <OwlCarousel className="owl-carousel owl-theme twocardslider" {...options}>
                {alumniData.map((alumni: any, index: any) => (
                    <div className="item" key={index}>
                        <div className="testimonial_block">
                            <div className="testimonial_img_brife">
                                <div className="testimonial_img_wrap">
                                    <div className="testimonial_img">
                                        <img src={alumni?.profile_pic} width="80" alt={alumni?.user_name} />
                                    </div>
                                </div>
                                <div className="testimonial_brife">
                                    <h3><span style={secondaryClass}>{alumni?.user_name}</span></h3>
                                    <p>{alumni?.user_program} | {alumni?.user_headline}</p>
                                </div>
                            </div>
                            <div className="testimonial_detail">
                                <p>{alumni?.user_testimonial}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
        </>
    );
};

export default AlumniSpeakList;
